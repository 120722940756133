<template>
  <vs-prompt
    v-if="eventData && eventData.event"
    class="prompt"
    :buttons-hidden="true"
    title=""
    @close="showEventPromptStatus = false, seenEvent(eventData)"
    :active.sync="showEventPromptStatus">

    <keep-alive>
      <new-version-event v-if="eventData.event.type === 1"
                         :event="eventData.event"
                         @seen="showEventPromptStatus = false, seenEvent(eventData)"/>

      <log-event v-else-if="eventData.event.type === 2"
                         :event="eventData.event"
                         @seen="showEventPromptStatus = false, seenEvent(eventData)"/>

      <message-event v-else-if="eventData.event.type === 3"
                         :event="eventData.event"
                         @seen="showEventPromptStatus = false, seenEvent(eventData)"/>

      <warning-event v-else-if="eventData.event.type === 4"
                         :event="eventData.event"
                         @seen="showEventPromptStatus = false, seenEvent(eventData)"/>
    </keep-alive>
  </vs-prompt>
</template>

<script>
import {seenEvent} from '../../http/requests/events'
import NewVersionEvent from './newVersionEvent'
import LogEvent from './logEvent'
import WarningEvent from './warningEvent'
import MessageEvent from './messageEvent'

export default {
  name: 'customEvent',
  components: {MessageEvent, WarningEvent, LogEvent, NewVersionEvent},
  props: {
    eventData: {},
    seenEventStatus: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showEventPromptStatus: false
    }
  },
  methods: {
    showEvent () {
      this.showEventPromptStatus = true
    },
    seenEvent (event) {
      if (this.seenEventStatus) seenEvent(event.event.id, event.id)
      this.$emit('seen')
      if (this.eventData.event.resource_type) {
        switch (this.eventData.event.resource_type) {
          case 'shop':
            setTimeout(() => {
              this.$router.push({ name: 'wooreceiverProfile', params: {id: this.eventData.event.resource_id} })
            }, 100)
            break

          case 'product':
            setTimeout(() => {
              this.$router.push({ name: 'Product', params: {id: this.eventData.event.resource_id} })
            }, 100)
            break

          case 'service':
            setTimeout(() => {
              this.$router.push({ name: 'Service', params: {id: this.eventData.event.resource_id} })
            }, 100)
            break

          case 'user':
            setTimeout(() => {
              this.$router.push({ name: 'user', params: {id: this.eventData.event.resource_id} })
            }, 100)
            break
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
