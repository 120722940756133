<template>
  <div class="warning-event">
    <div class="event-subject">
      <div class="event-title">
        <vs-icon class="mr-2"
                 color="warning"
                 icon="icon-alert-circle"
                 icon-pack="feather"/>
        <span class="text-warning">{{ event.subject }}</span>
      </div>

      <div class="event-time mt-1 text-xs">
        {{ convertUtcToLocale(event.created_at, $localeValidator('moment.dateTime')) }}
      </div>
    </div>

    <vs-divider class="mt-2 mb-5"/>

    <div class="event-content">
      <vue-markdown>{{ event.content }}</vue-markdown>

<!--      <button class="accept-button bg-warning"-->
<!--              @click="$emit('seen')">-->
<!--        {{ $t('events.event.labels.understand') }}-->
<!--      </button>-->
    </div>
  </div>
</template>

<script>
import vueMarkdown from 'vue-markdown'
import {convertUtcToLocale} from "../../assets/js/functions";

export default {
  name: 'warningEvent',
  components: {vueMarkdown},
  props: {
    event: {}
  },
  methods: {
    convertUtcToLocale(time, format) {
      return convertUtcToLocale(time, format)
    }
  }
}
</script>

<style lang="scss">
.warning-event {
  padding: 15px;
  height: 100%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    margin: auto;
  }

  .event-subject {
    //margin-top: 100px;
    font-weight: 500;
    font-size: 17px;
  }

  .event-content {
    margin-top: 10px;

    button.accept-button {
      display: block;
      cursor: pointer;
      padding: 10px 25px;
      color: #ffffff;
      //background: #77c533;
      border-radius: 30px;
      border: none;
      margin: 10px auto 0;
      transition: all .3s ease;
      box-shadow: 0 0 0 rgba(var(--vs-warning), 1);

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 5px rgba(var(--vs-warning), 1);
      }
    }

    ul {
      padding: 10px 20px !important;

      li {
        list-style: disc;
      }
    }
  }
}
</style>
