import axios from 'axios';

const CancelToken = axios.CancelToken;

export function createCancelTokenHandler(propertyName) {
  // initializing the cancel token handler object
  let cancelTokenHandler = {};

  // initializing the cancel token of the request
  const cancelTokenRequestHandler = {
    cancelToken: undefined
  }

  // associating the cancel token handler to the request name
  cancelTokenHandler = {
    handleRequestCancellation: () => {
      // if a previous cancel token exists,
      // cancel the request
      cancelTokenRequestHandler.cancelToken && cancelTokenRequestHandler.cancelToken.cancel(`${propertyName} canceled`)

      // creating a new cancel token
      cancelTokenRequestHandler.cancelToken = CancelToken.source();

      // returning the new cancel token
      return cancelTokenRequestHandler.cancelToken;
    }
  }

  return cancelTokenHandler;
}
